<template>
  <div class="outset_div">
    <div class="content">
      <div class="item">
        <div class="label required">姓名</div>
        <div class="value">
          <van-field v-model="form.name" label="" placeholder="请输入" />
        </div>
      </div>
      <van-divider />
      <div class="item">
        <div class="label required">性别</div>
        <div class="value">
          <van-field
            v-model="form.genderText"
            is-link
            readonly
            name="picker"
            label=""
            placeholder="请选择"
            @click="showSexPicker = true"
          />
          <van-popup v-model:show="showSexPicker" position="bottom">
            <van-picker :columns="columns" @confirm="onSexConfirm" @cancel="showPicker = false" />
          </van-popup>
        </div>
      </div>
      <van-divider />
      <div class="item">
        <div class="label required">手机号</div>
        <div class="value">
          <van-field v-model="form.mobile" label="" placeholder="请输入" type="tel" />
        </div>
      </div>
      <van-divider />
      <div class="item">
        <div class="label">邮箱</div>
        <div class="value">
          <van-field v-model="form.email" label="" placeholder="请输入" />
        </div>
      </div>
      <van-divider />
      <div class="item">
        <div class="label required">职务</div>
        <div class="value">
          <van-field v-model="form.duty_name" label="" placeholder="请输入" />
        </div>
      </div>
      <van-divider />
      <div class="item" style="height:165px">
        <div class="label">身份证照片</div>
        <div class="value">
          <uf-idcard-upload style="display: flex;" v-model="idcardData" />
        </div>
      </div>
      <van-divider />
      <div class="item">
        <div class="label">身份证号</div>
        <div class="value">
          <van-field v-model="form.id_card" label="" placeholder="请输入" />
        </div>
      </div>
      <van-divider />
      <div class="item" style="padding-bottom:16px">
        <div class="label">身份证有效期</div>
        <div class="value">
          <van-field
            :modelValue="idcardDateInfo.label"
            is-link
            readonly
            name="datetimePicker"
            label=""
            placeholder="请选择"
            @click="showPicker = true"
          />

          <uf-daterange-picker v-model:show="showPicker" v-model="daterangePickerData" />
        </div>
      </div>
      <van-divider />
    </div>
    <div class="btn">
      <van-button type="primary" block @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { toRefs, useTitle } from "@vueuse/core";
import validate from "@/utils/validate";
import { Toast } from "vant";
import { storesManageService, userService } from "@/service";
import { useStore } from "@/store";
import { useDate } from "@/composables";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const title = useTitle();
    if (route.query.userId) {
      title.value = "编辑人员";
    }

    const out_userid = ref("");
    const userInfo = computed(() => store.state.userInfo);
    const { daterangePickerDateFormat } = useDate();

    const showSexPicker = ref(false);
    const columns = ["男", "女"];

    const showPicker = ref(false);
    const daterangePickerData = ref([]);
    const idcardDateInfo = daterangePickerDateFormat(daterangePickerData);

    const idcardData = ref([]);

    const data = reactive({
      form: {
        role_id: "3",
        stores_id: userInfo.value.stores_id,
        job_state: "1", //在职状态 必填
        name: "", //姓名 必填
        gender: "", //性别：1男 2女 必填
        genderText: "", //
        mobile: "", //
        email: "", //
        duty_name: "", //职务名称 必填
        id_card: "" //
      }
    });

    const onSexConfirm = value => {
      data.form.genderText = value;
      data.form.gender = value == "男" ? "1" : "2";
      showSexPicker.value = false;
    };
    const resultTime = ref("");
    const showTimePicker = ref(false);

    const onTimeConfirm = value => {
      resultTime.value = value;
      showTimePicker.value = false;
    };

    const afterRead = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    };

    const submit = async () => {
      if (data.form.name == "") {
        Toast("请输入姓名");
        return;
      }
      if (!validate.name.reg.test(data.form.name)) {
        Toast("姓名" + validate.name.msg);
        return;
      }
      if (data.form.genderText == "") {
        Toast("请选择性别");
        return;
      }
      if (!validate.mobile.reg.test(data.form.mobile)) {
        Toast(validate.mobile.msg);
        return;
      }
      if (data.form.email != "" && !validate.email.reg.test(data.form.email)) {
        Toast(validate.email.msg);
        return;
      }
      if (data.form.duty_name == "") {
        Toast("请输入职务");
        return;
      }

      const obj = { ...data.form };

      if (idcardData.value.length > 0) {
        if (idcardData.value[0]) obj.positive = idcardData.value[0].res.id;
        if (idcardData.value[1]) obj.back = idcardData.value[1].res.id;
      }
      if (idcardDateInfo.value.startTimestamp) {
        obj.start_time = idcardDateInfo.value.startTimestamp;
      }
      if (idcardDateInfo.value.endTimestamp) {
        obj.end_time = idcardDateInfo.value.endTimestamp;
      }
      if (route.query.userId) {
        await storesManageService.userEdit({
          out_userid: out_userid.value,
          id: route.query.userId,
          ...obj
        });
      } else {
        await storesManageService.userAdd(obj);
      }

      router.back();
    };

    const getUserInfo = async () => {
      const { data: res } = await userService.userInfo({
        user_id: route.query.userId,
        user_information: 1
      });
      out_userid.value = res.data.out_userid;
      data.form.name = res.data.name; //姓名 必填
      data.form.gender = res.data.gender; //性别：1男 2女 必填
      data.form.genderText = res.data.gender_name; //
      data.form.mobile = res.data.mobile; //
      data.form.email = res.data.email; //
      data.form.duty_name = res.data.duty_name; //职务名称 必填
      data.form.id_card = res.data.id_card; //
      if (res.data.start_time) {
        daterangePickerData.value = [
          moment(res.data.start_time * 1000).toDate(),
          moment(res.data.end_time * 1000).toDate()
        ];
      }

      if (res.data.positive_detail.photo_thumb_file) {
        idcardData.value[0] = {
          url: res.data.positive_detail.photo_thumb_file,
          res: res.data.positive_detail
        };
      }
      if (res.data.back_detail.photo_thumb_file) {
        idcardData.value[1] = {
          url: res.data.back_detail.photo_thumb_file,
          res: res.data.back_detail
        };
      }
    };

    if (route.query.userId) {
      getUserInfo();
    }

    return {
      columns,
      onSexConfirm,
      showSexPicker,
      showPicker,
      resultTime,
      onTimeConfirm,
      showTimePicker,
      afterRead,
      ...toRefs(data),
      submit,
      idcardData,
      validate,
      daterangePickerData,
      idcardDateInfo
    };
  }
};
</script>

<style lang="less" scoped>
.outset_div {
  background-color: rgb(246, 247, 250);
  padding-top: 0.5px;
  padding-bottom: 120px;
  /deep/ .van-divider {
    width: 93%;
    margin: 0 0 0 10px;
    color: #ccd3df;
  }
  /deep/ .van-icon-arrow:before {
    color: #959fac;
  }
  .content {
    background-color: #fff;
    .item {
      height: 70px;
      width: 92%;
      margin: 15px 15px 0 15px;
      padding-top: 15px;
      //   border-bottom: 0.5px solid #CCD3DF;
      .label {
        font-family: PingFang-SC-ExtraLight;
        font-size: 15px;
        color: #2d333e;
        letter-spacing: 0;
        line-height: 15px;
        margin-bottom: 20px;
      }
      .required::after {
        margin-right: 2px;
        color: var(--van-field-required-mark-color);
        content: "*";
      }
      /deep/ .van-cell {
        padding: 0;
      }
      /deep/ .van-uploader__upload {
        width: 162px;
        height: 105px;
      }
    }
  }
  .btn {
    height: 58px;
    background: #ffffff;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 12px 10px;
  }
  /deep/ .van-button--block {
    width: 95%;
    font-size: 17px;
  }
}
</style>
